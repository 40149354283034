<template>
  <div class="space-y-3 mt-4">
    <div class="flex items-center gap-2">
      <div class="p-2 bg-pink-100 dark:bg-pink-900 rounded">
        <SvgUse
          name="ranking"
          path="/svgs/home.svg"
          class="w-6 h-6 text-pink-500 dark:text-white"
        />
      </div>
      <p class="text-xl font-bold">{{ LL.ranking_board() }}</p>
    </div>
    <div class="space-y-3">
      <template v-for="(author, idx) in authors" :key="author.id">
        <USeparator v-if="idx !== authors.length" />
        <HomeBlocksAuthorRankingItem :author="author" :position="idx" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TTopAuthor } from "~/features/post";
import { typesafeI18n } from "~/locales/i18n-vue";
type Props = {
  authors: TTopAuthor[];
};

withDefaults(defineProps<Props>(), {
  authors: () => [],
});

const { LL } = typesafeI18n();
</script>
